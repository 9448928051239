import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Page404Component } from './pages/page404/page404.component';

export const filePath: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.component').then((m) => m.HomeComponent),
    data: {
      sitemap: {
        loc: '/',
        lastmod: '2025-03-27',
      },
      meta: {
        loc: '/',
        fileName: 'HOME',
        title: 'Axon Trade - OEMS for Digital Asset Trading',
        description:
          'We provide a unique combination of low-latency execution services, real-time market data feeds, and a secure hosted environment inside Equinix data centers.',
        keywords:
          'crypto oems, crypto fix api, fix api, crypto exchange fix api, crypto trading, algotrading, crypto hft, high frequency trading crypto',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
        og_type: 'website',
      },
    },
  },
  {
    path: 'blog',
    loadComponent: () => import('./pages/blog/blog.component').then((m) => m.BlogComponent),
    data: {
      sitemap: {
        loc: 'blog',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'blog',
        fileName: '/blog',
        title: 'Blog | Axon Trade',
        description: 'New and updates on Axon Trade products and services',
        keywords:
          'fix api,market data,real time market data,fix api crypto,binance fix api,fix api for crypto trading,fix api for digital asset trading,industry,fix protocol,fix protocol for crypto,crypto exchange status,crypto latency arb,crypto latency arbitrage,latency arb trading,low-latency,mt4 crypto,mt5 crypto,metaquotes crypto,mt4 bridge for crypto,kucoin fix api,kucoin trading api,kucoin hft,bitget fix api,bitget trading api,bitget algo trading',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'products/fix-api',
    loadComponent: () => import('./pages/products/fix-api/fix-api.component').then((m) => m.FixApiComponent),
    data: {
      sitemap: {
        loc: 'products/fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'products/fix-api',
        fileName: 'products/fix-api',
        title: 'FIX API for digital asset trading | Axon Trade',
        description:
          'FIX API for digital asset trading, real-time normalized market data feeds, low-latency execution services, and a secure environment inside Equinix data centers.',
        keywords: 'fix api,crypto trading,cryptocurrency,digital assets,digital asset trading,crypto exchanges',
        imgUrl: '/assets/twitter-card/axon-trade-fix-api-twitter-card.jpg',
        og_type: 'article',
        canonical: 'products/fix-api',
      },
    },
  },
  {
    path: 'fix-api-overview',
    loadComponent: () =>
      import('./pages/products/fix-api-overview/fix-api-overview.component').then((m) => m.FixApiOverviewComponent),
    data: {
      sitemap: {
        loc: 'fix-api-overview',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'fix-api-overview',
        fileName: '/fix-api-overview',
        title: 'FIX API Overview | Axon Trade',
        description:
          'FIX API is a proven, decades old method of connecting markets. We have created our own that connects all of the major cryptocurrency exchanges. FIX is widely adopted & much faster',
        keywords: 'fix,financial information exchange,digital assets,crypto,cryptocurrency',
        imgUrl: '/assets/twitter-card/axon-trade-fix-api-overview-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'products/real-time-normalized-market-data',
    loadComponent: () =>
      import('./pages/products/market-data/market-data.component').then((m) => m.MarketDataComponent),
    data: {
      sitemap: {
        loc: 'products/real-time-normalized-market-data',
        lastmod: '2025-02-12',
      },
      meta: {
        loc: 'products/real-time-normalized-market-data',
        fileName: '/products/real-time-normalized-market-data',
        title: 'Real-time Normalized Crypto Market Data | Axon Trade',
        description:
          'Axon Trade provides real-time crypto market data through FIX and WebSockets API for all popular exchanges, including Binance, Coinbase, OKX, Kraken, and Huobi',
        keywords:
          'crypto market data,crypto real time market data,crypto market data api,crypto market data provider,binance market data,coinbase market data,kraken market data,okx market data',
        imgUrl: '/assets/twitter-card/axon-trade-market-data-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'supported-exchanges',
    loadComponent: () =>
      import('./pages/supported-exchanges/supported-exchanges.component').then((m) => m.SupportedExchangesComponent),
    data: {
      sitemap: {
        loc: 'supported-exchanges',
        lastmod: '2025-02-12',
      },
      meta: {
        loc: 'supported-exchanges',
        fileName: '/supported-exchanges',
        title: 'Supported Exchanges | Axon Trade',
        description:
          'Axon Trade is connected to 30+ crypto exchanges. We support all markets, available on exchanges - spot, margin, perpetual wasp, futures, and options',
        keywords:
          'crypto,crypto exchanges,crypto trading,binance,huobi,okx,bybit,bitmex,bitfinex,coinbase,hft,high frequency trading,algotrading',
        imgUrl: '/assets/twitter-card/axon-trade-supported-exchanges-page-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'ads/binance-fix-api',
    loadComponent: () =>
      import('./pages/ads/binance-fix-api/binance-fix-api.component').then((m) => m.BinanceFixApiComponent),
    data: {
      sitemap: {
        loc: 'ads/binance-fix-api',
        lastmod: '2025-02-07',
      },
      meta: {
        loc: 'ads/binance-fix-api',
        fileName: '/ads/binance-fix-api',
        title: 'Binance FIX API | Axon Trade',
        description:
          'FIX API for Binance and Binance US crypto exchange. Use Axon Trade OEMS to trade on Binance and Binance US and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'binance fix api,binance us fix api,binance professional api,binance trading,binance oems,binance hft,binance high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-binance-fix-api-page-twitter-card.jpg',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bitmart-fix-api',
    loadComponent: () =>
      import('./pages/ads/bitmart-fix-api/bitmart-fix-api.component').then((m) => m.BitMartFixApiComponent),
    data: {
      sitemap: {
        loc: 'bitmart-fix-api',
        lastmod: '2025-02-07',
      },
      meta: {
        loc: 'bitmart-fix-api',
        fileName: '/bitmart-fix-api',
        title: 'BitMart FIX API | Axon Trade',
        description:
          'FIX API for BitMart crypto exchange. Use Axon Trade OEMS to trade on BitMart and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'bitmart fix api,bitmart professional api,bitmart trading,bitmart oems,bitmart hft,bitmart high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-bitmart-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'kucoin-fix-api',
    loadComponent: () =>
      import('./pages/ads/kucoin-fix-api/kucoin-fix-api.component').then((m) => m.KucoinFixApiComponent),
    data: {
      sitemap: {
        loc: 'kucoin-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'kucoin-fix-api',
        fileName: '/kucoin-fix-api',
        title: 'Kucoin FIX API | Axon Trade',
        description:
          'FIX API for Kucoin crypto exchange. Use Axon Trade OEMS to trade on Kucoin and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'kucoin fix api,kucoin professional api,kucoin trading,kucoin oems,kucoin hft,kucoin high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-kucoin-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'coinbase-fix-api',
    loadComponent: () =>
      import('./pages/ads/coinbase-fix-api/coinbase-fix-api.component').then((m) => m.CoinbaseFixApiComponent),
    data: {
      sitemap: {
        loc: 'coinbase-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'coinbase-fix-api',
        fileName: '/coinbase-fix-api',
        title: 'Coinbase FIX API | Axon Trade',
        description:
          'FIX API for Coinbase crypto exchange. Use Axon Trade OEMS to trade on Coinbase and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'coinbase fix api,coinbase professional api,coinbase trading,coinbase oems,coinbase hft,coinbase high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-coinbase-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'htx-fix-api',
    loadComponent: () => import('./pages/ads/htx-fix-api/htx-fix-api.component').then((m) => m.HtxFixApiComponent),
    data: {
      sitemap: {
        loc: 'htx-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'htx-fix-api',
        fileName: '/htx-fix-api',
        title: 'HTX FIX API | Axon Trade',
        description:
          'FIX API for HTX crypto exchange. Use Axon Trade OEMS to trade on HTX and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords: 'htx fix api,htx professional api,htx trading,htx oems,htx hft,htx high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-htx-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'kraken-fix-api',
    loadComponent: () =>
      import('./pages/ads/kraken-fix-api/kraken-fix-api.component').then((m) => m.KrakenFixApiComponent),
    data: {
      sitemap: {
        loc: 'kraken-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'kraken-fix-api',
        fileName: '/kraken-fix-api',
        title: 'Kraken FIX API | Axon Trade',
        description:
          'FIX API for Kraken crypto exchange. Use Axon Trade OEMS to trade on Kraken and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'kraken fix api,kraken professional api,kraken trading,kraken oems,kraken hft,kraken high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-kraken-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bitstamp-fix-api',
    loadComponent: () =>
      import('./pages/ads/bitstamp-fix-api/bitstamp-fix-api.component').then((m) => m.BitstampFixApiComponent),
    data: {
      sitemap: {
        loc: 'bitstamp-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'bitstamp-fix-api',
        fileName: '/bitstamp-fix-api',
        title: 'Bitstamp FIX API | Axon Trade',
        description:
          'FIX API for Bitstamp crypto exchange. Use Axon Trade OEMS to trade on Bitstamp and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'bitstamp fix api,bitstamp professional api,bitstamp trading,bitstamp oems,bitstamp hft,bitstamp high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-bitstamp-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'gemini-fix-api',
    loadComponent: () =>
      import('./pages/ads/gemini-fix-api/gemini-fix-api.component').then((m) => m.GeminiFixApiComponent),
    data: {
      sitemap: {
        loc: 'gemini-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'gemini-fix-api',
        fileName: '/gemini-fix-api',
        title: 'Gemini FIX API | Axon Trade',
        description:
          'FIX API for Gemini crypto exchange. Use Axon Trade OEMS to trade on Gemini and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'gemini fix api,gemini professional api,gemini trading,gemini oems,gemini hft,gemini high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-gemini-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'okx-fix-api',
    loadComponent: () => import('./pages/ads/okx-fix-api/okx-fix-api.component').then((m) => m.OkxFixApiComponent),
    data: {
      sitemap: {
        loc: 'okx-fix-api',
        lastmod: '2025-02-03',
      },
      meta: {
        loc: 'okx-fix-api',
        fileName: '/okx-fix-api',
        title: 'OKX FIX API | Axon Trade',
        description:
          'FIX API for OKX crypto exchange. Use Axon Trade OEMS to trade on OKX and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords: 'okx fix api,okx professional api,okx trading,okx oems,okx hft,okx high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-okx-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'hitbtc-fix-api',
    loadComponent: () =>
      import('./pages/ads/hitbtc-fix-api/hitbtc-fix-api.component').then((m) => m.HitbtcFixApiComponent),
    data: {
      sitemap: {
        loc: 'hitbtc-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'hitbtc-fix-api',
        fileName: '/hitbtc-fix-api',
        title: 'HitBTC FIX API | Axon Trade',
        description:
          'FIX API for HitBTC crypto exchange. Use Axon Trade OEMS to trade on HitBTC and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'hitbtc fix api,hitbtc professional api,hitbtc trading,hitbtc oems,hitbtc hft,hitbtc high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-hitbtc-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'cexio-fix-api',
    loadComponent: () =>
      import('./pages/ads/cexio-fix-api/cexio-fix-api.component').then((m) => m.CexioFixApiComponent),
    data: {
      sitemap: {
        loc: 'cexio-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'cexio-fix-api',
        fileName: '/cexio-fix-api',
        title: 'CEX.IO FIX API | Axon Trade',
        description:
          'FIX API for CEX.IO crypto exchange. Use Axon Trade OEMS to trade on CEX.IO and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'cex.io fix api,cex.io professional api,cex.io trading,cex.io oems,cex.io hft,cex.io high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-cexio-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bitmex-fix-api',
    loadComponent: () =>
      import('./pages/ads/bitmex-fix-api/bitmex-fix-api.component').then((m) => m.BitmexFixApiComponent),
    data: {
      sitemap: {
        loc: 'bitmex-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'bitmex-fix-api',
        fileName: '/bitmex-fix-api',
        title: 'BitMEX FIX API | Axon Trade',
        description:
          'FIX API for BitMEX crypto exchange. Use Axon Trade OEMS to trade on BitMEX and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'bitmex fix api,bitmex professional api,bitmex trading,bitmex oems,bitmex hft,bitmex high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-bitmex-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bybit-fix-api',
    loadComponent: () =>
      import('./pages/ads/bybit-fix-api/bybit-fix-api.component').then((m) => m.BybitFixApiComponent),
    data: {
      sitemap: {
        loc: 'bybit-fix-api',
        lastmod: '2025-02-07',
      },
      meta: {
        loc: 'bybit-fix-api',
        fileName: '/bybit-fix-api',
        title: 'Bybit FIX API | Axon Trade',
        description:
          'FIX API for Bybit crypto exchange. Use Axon Trade OEMS to trade on Bybit and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'bybit fix api,bybit professional api,bybit trading,bybit oems,bybit hft,bybit high frequency trading,crypto,crypto trading,bybit,fix api',
        imgUrl: '/assets/twitter-card/axon-trade-bybit-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bitfinex-fix-api',
    loadComponent: () =>
      import('./pages/ads/bitfinex-fix-api/bitfinex-fix-api.component').then((m) => m.BitfinexFixApiComponent),
    data: {
      sitemap: {
        loc: 'bitfinex-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'bitfinex-fix-api',
        fileName: '/bitfinex-fix-api',
        title: 'Bitfinex FIX API | Axon Trade',
        description:
          'FIX API for Bitfinex crypto exchange. Use Axon Trade OEMS to trade on Bitfinex and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'bitfinex fix api,bitfinex professional api,bitfinex trading,bitfinex oems,bitfinex hft,bitfinex high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-bitfinex-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bithumb-fix-api',
    loadComponent: () =>
      import('./pages/ads/bithumb-fix-api/bithumb-fix-api.component').then((m) => m.BithumbFixApiComponent),
    data: {
      sitemap: {
        loc: 'bithumb-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'bithumb-fix-api',
        fileName: '/bithumb-fix-api',
        title: 'Bithumb FIX API | Axon Trade',
        description:
          'FIX API for Bithumb crypto exchange. Use Axon Trade OEMS to trade on Bithumb and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'bithumb fix api,bithumb professional api,bithumb trading,bithumb oems,bithumb hft,bithumb high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-bithumb-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'gateio-fix-api',
    loadComponent: () =>
      import('./pages/ads/gateio-fix-api/gateio-fix-api.component').then((m) => m.GateioFixApiComponent),
    data: {
      sitemap: {
        loc: 'gateio-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'gateio-fix-api',
        fileName: '/gateio-fix-api',
        title: 'Gate.io FIX API | Axon Trade',
        description:
          'FIX API for Gate.io crypto exchange. Use Axon Trade OEMS to trade on Gate.io and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'gate.io fix api,gate.io professional api,gate.io trading,gate.io oems,gate.io hft,gate.io high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-gateio-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'probit-fix-api',
    loadComponent: () =>
      import('./pages/ads/probit-fix-api/probit-fix-api.component').then((m) => m.ProbitFixApiComponent),
    data: {
      sitemap: {
        loc: 'probit-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'probit-fix-api',
        fileName: '/probit-fix-api',
        title: 'ProBit FIX API | Axon Trade',
        description:
          'FIX API for ProBit crypto exchange. Use Axon Trade OEMS to trade on ProBit and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'probit fix api,probit professional api,probit trading,probit oems,probit hft,probit high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-probit-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'deribit-fix-api',
    loadComponent: () =>
      import('./pages/ads/deribit-fix-api/deribit-fix-api.component').then((m) => m.DeribitFixApiComponent),
    data: {
      sitemap: {
        loc: 'deribit-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'deribit-fix-api',
        fileName: '/deribit-fix-api',
        title: 'Deribit FIX API | Axon Trade',
        description:
          'FIX API for Deribit crypto exchange. Use Axon Trade OEMS to trade on Deribit and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'deribit fix api,deribit professional api,deribit trading,deribit oems,deribit hft,deribit high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-deribit-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'yobit-fix-api',
    loadComponent: () =>
      import('./pages/ads/yobit-fix-api/yobit-fix-api.component').then((m) => m.YobitFixApiComponent),
    data: {
      sitemap: {
        loc: 'yobit-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'yobit-fix-api',
        fileName: '/yobit-fix-api',
        title: 'YoBit FIX API | Axon Trade',
        description:
          'FIX API for YoBit crypto exchange. Use Axon Trade OEMS to trade on YoBit and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'yobit fix api,yobit professional api,yobit trading,yobit oems,yobit hft,yobit high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-yobit-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'exmo-fix-api',
    loadComponent: () => import('./pages/ads/exmo-fix-api/exmo-fix-api.component').then((m) => m.ExmoFixApiComponent),
    data: {
      sitemap: {
        loc: 'exmo-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'exmo-fix-api',
        fileName: '/exmo-fix-api',
        title: 'EXMO FIX API | Axon Trade',
        description:
          'FIX API for EXMO crypto exchange. Use Axon Trade OEMS to trade on EXMO and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords: 'exmo fix api,exmo professional api,exmo trading,exmo oems,exmo hft,exmo high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-exmo-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bitflyer-fix-api',
    loadComponent: () =>
      import('./pages/ads/bitflyer-fix-api/bitflyer-fix-api.component').then((m) => m.BitflyerFixApiComponent),
    data: {
      sitemap: {
        loc: 'bitflyer-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'bitflyer-fix-api',
        fileName: '/bitflyer-fix-api',
        title: 'BitFlyer FIX API | Axon Trade',
        description:
          'FIX API for BitFlyer crypto exchange. Use Axon Trade OEMS to trade on BitFlyer and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'bitfyer fix api,bitfyer professional api,bitfyer trading,bitfyer oems,bitfyer hft,bitfyer high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-bitflyer-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'amber-fix-api',
    loadComponent: () =>
      import('./pages/ads/amber-fix-api/amber-fix-api.component').then((m) => m.AmberFixApiComponent),
    data: {
      sitemap: {
        loc: 'amber-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'amber-fix-api',
        fileName: '/amber-fix-api',
        title: 'Amber FIX API | Axon Trade',
        description:
          'FIX API for Amber crypto exchange. Use Axon Trade OEMS to trade on Amber and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'amber fix api,amber professional api,amber trading,amber oems,amber hft,amber high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-amber-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'whitebit-fix-api',
    loadComponent: () =>
      import('./pages/ads/whitebit-fix-api/whitebit-fix-api.component').then((m) => m.WhitebitFixApiComponent),
    data: {
      sitemap: {
        loc: 'whitebit-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'whitebit-fix-api',
        fileName: '/whitebit-fix-api',
        title: 'WhiteBIT FIX API | Axon Trade',
        description:
          'FIX API for WhiteBIT crypto exchange. Use Axon Trade OEMS to trade on WhiteBIT and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'whitebit fix api,whitebit professional api,whitebit trading,whitebit oems,whitebit hft,whitebit high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-whitebit-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bitget-fix-api',
    loadComponent: () =>
      import('./pages/ads/bitget-fix-api/bitget-fix-api.component').then((m) => m.BitgetFixApiComponent),
    data: {
      sitemap: {
        loc: 'bitget-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'bitget-fix-api',
        fileName: '/bitget-fix-api',
        title: 'Bitget FIX API | Axon Trade',
        description:
          'FIX API for Bitget crypto exchange. Use Axon Trade OEMS to trade on Bitget and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'bitget fix api,bitget professional api,bitget trading,bitget oems,bitget hft,bitget high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-bitget-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'rulematch-fix-api',
    loadComponent: () =>
      import('./pages/ads/rulematch-fix-api/rulematch-fix-api.component').then((m) => m.RulematchFixApiComponent),
    data: {
      sitemap: {
        loc: 'rulematch-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'rulematch-fix-api',
        fileName: '/rulematch-fix-api',
        title: 'Rulematch FIX API | Axon Trade',
        description:
          'FIX API for Rulematch crypto exchange. Use Axon Trade OEMS to trade on Rulematch and 30+ other crypto exchanges using FIX 4.4 API.',
        keywords:
          'rulematch fix api,rulematch professional api,rulematch trading,rulematch oems,rulematch hft,rulematch high frequency trading',
        imgUrl: '/assets/twitter-card/axon-trade-rulematch-fix-api-page-twitter-card.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'contact-us',
    loadComponent: () => import('./pages/contact-us/contact-us.component').then((m) => m.ContactUsComponent),
    data: {
      sitemap: {
        loc: 'contact-us',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'contact-us',
        fileName: '/contact-us',
        title: 'Axon Trade - OEMS for Digital Asset Trading',
        description:
          'We provide a unique combination of low-latency execution services, real-time market data feeds, and a secure hosted environment inside Equinix data centers.',
        keywords:
          'crypto oems, crypto fix api, fix api, crypto exchange fix api, crypto trading, algotrading, crypto hft, high frequency trading crypto',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
        og_type: 'website',
      },
    },
  },
  {
    path: 'access-demo',
    loadComponent: () =>
      import('./pages/access-demo-environment/access-demo-environment.component').then(
        (m) => m.AccessDemoEnvironmentComponent
      ),
    data: {
      sitemap: {
        loc: 'access-demo',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'access-demo',
        fileName: '/access-demo',
        title: 'Axon Trade - OEMS for Digital Asset Trading',
        description:
          'We provide a unique combination of low-latency execution services, real-time market data feeds, and a secure hosted environment inside Equinix data centers.',
        keywords:
          'crypto oems, crypto fix api, fix api, crypto exchange fix api, crypto trading, algotrading, crypto hft, high frequency trading crypto',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
        og_type: 'website',
      },
    },
  },
  {
    path: 'ethereum-in-2025',
    loadComponent: () =>
      import('./pages/blog/pages/2025/April/ethereum-in-2025/ethereum-in-2025.component').then(
        (m) => m.EthereumIn2025Component
      ),
    data: {
      sitemap: {
        loc: 'ethereum-in-2025',
        lastmod: '2025-04-03',
      },
      meta: {
        loc: 'ethereum-in-2025',
        fileName: '/ethereum-in-2025',
        title: 'Ethereum in 2025\u2236 Network, Usage, and Upgrades',
        description:
          'The article provides a detailed update on Ethereum as of March 2025, covering its validator architecture, staking trends, rollup usage, protocol upgrades, and institutional adoption across finance, custody, and tokenization.',
        keywords:
          'ethereum 2025, ethereum staking, ethereum rollups, ethereum validators, ethereum protocol upgrade, ethereum blobs, eip-4844, ethereum pectra, ethereum layer 2, ethereum for institutions, tokenized funds ethereum, ethereum custody, ethereum defi, ethereum mainnet, ethereum pos, ethereum snapshot, ethereum usage data',
        imgUrl: '/assets/blog-card-images/ethereum-in-2025.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'a-practical-overview-of-crypto-trading-order-types',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/April/a-practical-overview-of-crypto-trading-order-types/a-practical-overview-of-crypto-trading-order-types.component'
      ).then((m) => m.APracticalOverviewOfCryptoTradingOrderTypesComponent),
    data: {
      sitemap: {
        loc: 'a-practical-overview-of-crypto-trading-order-types',
        lastmod: '2025-04-01',
      },
      meta: {
        loc: 'a-practical-overview-of-crypto-trading-order-types',
        fileName: '/a-practical-overview-of-crypto-trading-order-types',
        title: 'A Practical Overview of Crypto Trading Order Types',
        description:
          'The article offers an advanced analysis of all order types in cryptocurrency trading across both spot and derivatives markets, including market, limit, stop-loss, stop-limit, trailing stop, OCO, iceberg, TWAP, VWAP, and others. It explains their mechanics, use cases, associated risks, and implementation differences across centralized (CEX) and decentralized (DEX) platforms. A comparative table highlights execution speed, slippage, and strategy suitability, providing valuable insights for institutional traders, quant developers, and experienced retail participants.',
        keywords:
          'crypto trading order types, market order, limit order, stop-loss, stop-limit, trailing stop, OCO order, iceberg order, TWAP, VWAP, order execution, centralized exchange, decentralized exchange, derivatives trading, algorithmic trading, execution risk, slippage control, time-in-force, post-only, reduce-only, trading strategy, spot vs futures, crypto market microstructure, institutional trading, quantitative trading, advanced trading systems',
        imgUrl: '/assets/blog-card-images/a-practical-overview-of-crypto-trading-order-types.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'multi-agent-deep-q-learning',
    loadComponent: () =>
      import('./pages/blog/pages/2025/March/multi-agent-deep-q-learning/multi-agent-deep-q-learning.component').then(
        (m) => m.MultiAgentDeepQLearningComponent
      ),
    data: {
      sitemap: {
        loc: 'multi-agent-deep-q-learning',
        lastmod: '2025-03-27',
      },
      meta: {
        loc: 'multi-agent-deep-q-learning',
        fileName: '/multi-agent-deep-q-learning',
        title: 'Multi-Agent Deep Q-Learning for Trading Strategy Optimization',
        description:
          'This article presents a multi-agent Deep Q-Learning framework for developing adaptive trading strategies across multiple cryptocurrencies. By training separate agents with varied decision models on shared historical data, the approach enables improved prediction accuracy, strategy diversification, and dynamic decision-making. Empirical results on assets like BTC, ETH, and XRP show superior performance over traditional buy-and-hold strategies under diverse market conditions, including uptrends, downtrends, and sideways movements.',
        keywords:
          'deep Q-learning, cryptocurrency trading, multi-agent systems, reinforcement learning, algorithmic trading, adaptive trading strategies, crypto market prediction, deep RL agents, trading automation, buy-and-hold comparison, market regimes, trading strategy evaluation, execution modeling, financial AI systems, trading with Q-learning',
        imgUrl: '/assets/blog-card-images/multi-agent-deep-q-learning.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'how-reservation-strategies-shape-optimal-trading',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/March/how-reservation-strategies-shape-optimal-trading/how-reservation-strategies-shape-optimal-trading.component'
      ).then((m) => m.HowReservationStrategiesShapeOptimalTradingComponent),
    data: {
      sitemap: {
        loc: 'how-reservation-strategies-shape-optimal-trading',
        lastmod: '2025-03-25',
      },
      meta: {
        loc: 'how-reservation-strategies-shape-optimal-trading',
        fileName: '/how-reservation-strategies-shape-optimal-trading',
        title: 'How Reservation Strategies Shape Optimal Trading',
        description:
          'This article explains how brokers can optimize order execution under uncertainty while following client-imposed reservation strategies like TWAP or IS. Using a utility-maximizing framework and extensions of the Almgren-Chriss model, it shows how to balance market impact, execution risk, and benchmark tracking. Simulations demonstrate improved performance compared to TWAP strategies, offering insights into adaptive, risk-aware execution under real-world conditions.',
        keywords:
          'optimal order execution, reservation strategies, TWAP, IS, execution risk, Almgren-Chriss model, market impact, smart execution, utility-based trading, stochastic execution models, algorithmic trading, trading strategies, execution optimization, adaptive execution, high-frequency trading, institutional trading, financial modeling, quantitative finance',
        imgUrl: '/assets/blog-card-images/how-reservation-strategies-shape-optimal-trading.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'risk-aware-linear-bandits',
    loadComponent: () =>
      import('./pages/blog/pages/2025/March/risk-aware-linear-bandits/risk-aware-linear-bandits.component').then(
        (m) => m.RiskAwareLinearBanditsComponent
      ),
    data: {
      sitemap: {
        loc: 'risk-aware-linear-bandits',
        lastmod: '2025-03-20',
      },
      meta: {
        loc: 'risk-aware-linear-bandits',
        fileName: '/risk-aware-linear-bandits',
        title: 'Risk-Aware Linear Bandits in Algorithmic Trading',
        description:
          'This article explores the application of risk-aware linear bandits in smart order routing (SOR), focusing on how the RISE and RISE++ algorithms optimize order execution across multiple trading venues. By balancing reward maximization with risk control, these models improve execution quality, reduce transaction costs, and enhance adaptability to changing market conditions. The article also examines empirical validation using NASDAQ ITCH data, demonstrating the effectiveness of risk-aware bandits in real-world trading environments.',
        keywords:
          'risk-aware bandits, smart order routing, algorithmic trading, financial machine learning, execution optimization, trading infrastructure, mean-variance optimization, order execution, market liquidity, risk-aware decision-making, high-frequency trading, multi-armed bandits, adaptive learning, institutional trading, trade execution strategies, order flow, risk-adjusted returns, quantitative finance',
        imgUrl: '/assets/blog-card-images/risk-aware-linear-bandits.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'optimizing-cross-chain-arbitrage',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/March/optimizing-cross-chain-arbitrage/optimizing-cross-chain-arbitrage.component'
      ).then((m) => m.OptimizingCrossChainArbitrageComponent),
    data: {
      sitemap: {
        loc: 'optimizing-cross-chain-arbitrage',
        lastmod: '2025-03-18',
      },
      meta: {
        loc: 'optimizing-cross-chain-arbitrage',
        fileName: '/optimizing-cross-chain-arbitrage',
        title: 'Optimizing Cross-Chain Arbitrage in Decentralized Exchanges',
        description:
          'This article explores the concept of cross-chain arbitrage in decentralized exchanges, focusing on the mechanics of arbitrage opportunities between PancakeSwap on the BNB Chain and QuickSwap on Polygon. It examines the impact of transaction costs, liquidity dynamics, and blockchain interoperability, providing insights into the challenges and potential profitability of executing cross-chain arbitrage strategies in decentralized finance.',
        keywords:
          'cross-chain arbitrage, decentralized exchanges, liquidity pools, PancakeSwap, QuickSwap, blockchain interoperability, DeFi arbitrage, transaction costs, decentralized finance, arbitrage opportunities, market efficiency, cross-chain trading, arbitrage strategies, liquidity fragmentation, blockchain price discrepancies, cryptocurrency trading',
        imgUrl: '/assets/blog-card-images/optimizing-cross-chain-arbitrage.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'state-dependent-stochastic-models',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/March/state-dependent-stochastic-models/state-dependent-stochastic-models.component'
      ).then((m) => m.StateDependentStochasticModelsComponent),
    data: {
      sitemap: {
        loc: 'state-dependent-stochastic-models',
        lastmod: '2025-03-12',
      },
      meta: {
        loc: 'state-dependent-stochastic-models',
        fileName: '/state-dependent-stochastic-models',
        title: 'State-Dependent Stochastic Models for Fill Probability Estimation',
        description:
          'The article explores the concept of fill probability in limit order books and how state-dependent stochastic models provide more accurate predictions for traders. By considering dynamic factors like liquidity and volatility, these models offer a better understanding of order flow and execution risks, ultimately helping to optimize trading strategies and reduce transaction costs.',
        keywords:
          'fill probability, limit order book, algorithmic trading, execution strategies, stochastic models, market liquidity, high-frequency trading, order flow, trading risk, transaction cost optimization, crypto, cryptocurrency, trading strategies, algotrading',
        imgUrl: '/assets/blog-card-images/state-dependent-stochastic-models.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'axon-trade-partners-with-elven',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/March/axon-trade-partners-with-elven/axon-trade-partners-with-elven.component'
      ).then((m) => m.AxonTradePartnersWithElvenComponent),
    data: {
      sitemap: {
        loc: 'axon-trade-partners-with-elven',
        lastmod: '2025-03-11',
      },
      meta: {
        loc: 'axon-trade-partners-with-elven',
        fileName: '/axon-trade-partners-with-elven',
        title: 'Axon Trade Partners With Elven',
        description:
          'Elven and Axon Trade are joining forces to improve institutional trading infrastructure. This partnership delivers deep liquidity, low-latency execution, and seamless integration for professional traders. With a focus on stability and performance, the collaboration provides robust connectivity, advanced order routing, and reliable market access across multiple asset classes.',
        keywords:
          'elven, axon trade, institutional trading, algorithmic trading, low-latency execution, deep liquidity, market connectivity, order routing, trading infrastructure, financial technology, professional traders, electronic trading, market access, trading platforms, liquidity providers, high-frequency trading',
        imgUrl: '/assets/blog-card-images/axon-trade-partners-with-elven.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'one-hundred-books-for-algorithmic-trading-part-7',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/March/one-hundred-books-for-algorithmic-trading-part-7/one-hundred-books-for-algorithmic-trading-part-7.component'
      ).then((m) => m.OneHundredBooksForAlgorithmicTradingPart7Component),
    data: {
      sitemap: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-7',
        lastmod: '2025-03-24',
      },
      meta: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-7',
        fileName: '/one-hundred-books-for-algorithmic-trading-part-7',
        title: '100 Books for Algorithmic Trading Professionals - Part 7\u2236 Trading Psychology',
        description:
          'This article covers books that explore the psychological aspects of trading, from managing risk and handling stress to recognizing biases that impact financial choices. Topics include discipline, mental resilience, and strategies for maintaining focus in volatile markets. Traders will find insights from psychology, neuroscience, and behavioral finance to improve performance and build confidence.',
        keywords:
          'trading psychology, trader mindset, cognitive biases, behavioral finance, emotional resilience, risk management, trading discipline, decision-making in trading, market psychology, trading performance, self-discipline, stress management in trading, psychology of investing, financial decision-making, mental strategies for traders, neuroscience of trading, trading behavior, psychology of risk',
        imgUrl: '/assets/blog-card-images/one-hundred-books-for-algorithmic-trading-part-7.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'one-hundred-books-for-algorithmic-trading-part-6',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/March/one-hundred-books-for-algorithmic-trading-part-6/one-hundred-books-for-algorithmic-trading-part-6.component'
      ).then((m) => m.OneHundredBooksForAlgorithmicTradingPart6Component),
    data: {
      sitemap: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-6',
        lastmod: '2025-03-24',
      },
      meta: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-6',
        fileName: '/one-hundred-books-for-algorithmic-trading-part-6',
        title: '100 Books for Algorithmic Trading Professionals - Part 6\u2236 Case Studies and Real-World Stories',
        description:
          'History shapes modern trading. This article presents a selection of books that explore real-world case studies of algorithmic trading, financial crises, and market manipulation. From high-frequency trading to the rise of crypto, these books reveal the decisions and events that redefined global finance. Traders, analysts, and investors will gain insights into how markets evolve, the impact of hedge funds, and the role of technology in reshaping financial systems.',
        keywords:
          'algorithmic trading, financial markets, high-frequency trading, hedge funds, market manipulation, financial history, flash crash, crypto markets, decentralized finance, economic crises, hedge fund strategies, market speculation, algorithmic traders, trading technology, financial bubbles, Wall Street stories, institutional trading, risk management, market structure, financial regulation',
        imgUrl: '/assets/blog-card-images/one-hundred-books-for-algorithmic-trading-part-6.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'one-hundred-books-for-algorithmic-trading-part-5',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/March/one-hundred-books-for-algorithmic-trading-part-5/one-hundred-books-for-algorithmic-trading-part-5.component'
      ).then((m) => m.OneHundredBooksForAlgorithmicTradingPart5Component),
    data: {
      sitemap: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-5',
        lastmod: '2025-03-24',
      },
      meta: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-5',
        fileName: '/one-hundred-books-for-algorithmic-trading-part-5',
        title:
          '100 Books for Algorithmic Trading Professionals - Part 5\u2236 Portfolio Optimization and Risk Management',
        description:
          'Effective portfolio optimization and risk management are essential for long-term trading success. This article explores books covering asset allocation, risk forecasting, stress testing, and quantitative techniques like Bayesian estimation and machine learning. From classical portfolio theory to modern risk modeling, these resources provide practical insights for traders, asset managers, and financial professionals looking to build resilient portfolios and navigate market uncertainty',
        keywords:
          'portfolio optimization, risk management, asset allocation, financial modeling, quantitative finance, Bayesian estimation, machine learning in trading, stress testing, value-at-risk, Monte Carlo simulation, hedge funds, investment strategies, portfolio construction, market risk, portfolio theory, risk forecasting, algorithmic trading, financial risk analysis, Black-Litterman model, asset management, volatility analysis',
        imgUrl: '/assets/blog-card-images/one-hundred-books-for-algorithmic-trading-part-5.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'one-hundred-books-for-algorithmic-trading-part-4',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/February/one-hundred-books-for-algorithmic-trading-part-4/one-hundred-books-for-algorithmic-trading-part-4.component'
      ).then((m) => m.OneHundredBooksForAlgorithmicTradingPart4Component),
    data: {
      sitemap: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-4',
        lastmod: '2025-03-24',
      },
      meta: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-4',
        fileName: '/one-hundred-books-for-algorithmic-trading-part-4',
        title: '100 Books for Algorithmic Trading Professionals - Part 4\u2236  AI and Machine Learning in Trading',
        description:
          'Machine learning is transforming trading by uncovering patterns, optimizing execution, and managing risk at scale. This selection of books covers regression models, neural networks, reinforcement learning, and AI-powered risk assessment. Readers will explore high-frequency trading, statistical arbitrage, and market simulation, gaining insights into structuring data pipelines and refining predictive models.',
        keywords:
          'algorithmic trading, AI in trading, machine learning in finance, quantitative trading, deep learning for trading, reinforcement learning, financial modeling, high-frequency trading, statistical arbitrage, risk management, trading algorithms, market data analysis, predictive analytics, AI trading systems, algorithmic execution, portfolio optimization, automated trading',
        imgUrl: '/assets/blog-card-images/one-hundred-books-for-algorithmic-trading-part-4.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'one-hundred-books-for-algorithmic-trading-part-3',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/February/one-hundred-books-for-algorithmic-trading-part-3/one-hundred-books-for-algorithmic-trading-part-3.component'
      ).then((m) => m.OneHundredBooksForAlgorithmicTradingPart3Component),
    data: {
      sitemap: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-3',
        lastmod: '2025-03-24',
      },
      meta: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-3',
        fileName: '/one-hundred-books-for-algorithmic-trading-part-3',
        title: '100 Books for Algorithmic Trading Professionals - Part 3\u2236  Coding and Tools for Trading',
        description:
          'Efficient trading algorithms rely on well-structured code. This selection of books covers Python, R, Java, and C++ for algorithmic trading, including topics like backtesting frameworks, data pipelines, risk modeling, and API integrations. From optimizing execution speed to automating research, these resources help traders and developers build scalable financial systems.',
        keywords:
          'algorithmic trading, algo trading, quant trading, trading algorithms, financial programming, Python for trading, R for trading, Java for trading, C++ trading systems, backtesting, financial modeling, quantitative finance, high-frequency trading, systematic trading, risk management, trading software, API integrations, market data processing, trading automation',
        imgUrl: '/assets/blog-card-images/one-hundred-books-for-algorithmic-trading-part-3.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'one-hundred-books-for-algorithmic-trading-part-2',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/February/one-hundred-books-for-algorithmic-trading-part-2/one-hundred-books-for-algorithmic-trading-part-2.component'
      ).then((m) => m.OneHundredBooksForAlgorithmicTradingPart2Component),
    data: {
      sitemap: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-2',
        lastmod: '2025-03-24',
      },
      meta: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-2',
        fileName: '/one-hundred-books-for-algorithmic-trading-part-2',
        title: '100 Books for Algorithmic Trading Professionals - Part 2\u2236  Math & Statistics for Trading',
        description:
          'Mathematics is the foundation of algorithmic trading. From probability theory to stochastic calculus, a solid grasp of mathematical concepts allows traders to analyze data, measure risk, and refine their strategies. This selection of books covers essential topics, including financial modeling, statistical arbitrage, and quantitative methods used in modern trading. ',
        keywords:
          'algorithmic trading, algotrading, auant trading, financial mathematics, probability theory, stochastic calculus, risk management, market microstructure, statistical arbitrage, quantitative finance, data-driven trading, trading models, financial engineering, quantitative strategies, high-frequency trading, systematic trading, financial markets, trading systems',
        imgUrl: '/assets/blog-card-images/one-hundred-books-for-algorithmic-trading-part-2.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'one-hundred-books-for-algorithmic-trading-part-1',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/February/one-hundred-books-for-algorithmic-trading-part-1/one-hundred-books-for-algorithmic-trading-part-1.component'
      ).then((m) => m.OneHundredBooksForAlgorithmicTradingPart1Component),
    data: {
      sitemap: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-1',
        lastmod: '2025-03-24',
      },
      meta: {
        loc: 'one-hundred-books-for-algorithmic-trading-part-1',
        fileName: '/one-hundred-books-for-algorithmic-trading-part-1',
        title: '100 Books for Algorithmic Trading Professionals - Part 1\u2236 Foundational Concepts',
        description:
          'This article lists essential books on algorithmic trading, covering market structure, trading strategies, risk management, and system development. It includes foundational works on quantitative models, high-frequency trading, and portfolio management, offering key insights for traders and researchers.',
        keywords:
          'algorithmic trading books, algorithmic trading, quantitative trading, trading strategies, market structure, risk management, backtesting, portfolio management, high-frequency trading, systematic trading, financial markets, trading systems',
        imgUrl: '/assets/blog-card-images/one-hundred-books-for-algorithmic-trading-part-1.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'improving-cryptocurrency-predictions-with-rnn-models',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/February/improving-cryptocurrency-predictions-with-rnn-models/improving-cryptocurrency-predictions-with-rnn-models.component'
      ).then((m) => m.ImprovingCryptocurrencyPredictionsWithRnnModelsComponent),
    data: {
      sitemap: {
        loc: 'improving-cryptocurrency-predictions-with-rnn-models',
        lastmod: '2025-02-12',
      },
      meta: {
        loc: 'improving-cryptocurrency-predictions-with-rnn-models',
        fileName: '/improving-cryptocurrency-predictions-with-rnn-models',
        title: 'Improving Cryptocurrency Predictions with RNN Models',
        description:
          'Explore how advanced RNN models, including LSTM, GRU, and Bi-LSTM, are transforming cryptocurrency price forecasting. This article examines how deep learning techniques enhance trading strategies with improved accuracy and actionable insights for traders and analysts.',
        keywords:
          'cryptocurrency predictions, RNN models, LSTM, GRU, Bi-LSTM, price forecasting, trading strategies, deep learning in finance, real-time cryptocurrency trading, financial time series, optimized trading models',
        imgUrl: '/assets/blog-card-images/improving-cryptocurrency-predictions-with-rnn-models.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'profitable-arbitrage-with-dynamic-cointegration',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/February/profitable-arbitrage-with-dynamic-cointegration/profitable-arbitrage-with-dynamic-cointegration.component'
      ).then((m) => m.ProfitableArbitrageWithDynamicCointegrationComponent),
    data: {
      sitemap: {
        loc: 'profitable-arbitrage-with-dynamic-cointegration',
        lastmod: '2025-02-10',
      },
      meta: {
        loc: 'profitable-arbitrage-with-dynamic-cointegration',
        fileName: '/profitable-arbitrage-with-dynamic-cointegration',
        title: 'Profitable Arbitrage with Dynamic Cointegration in Cryptocurrencies',
        description:
          'An in-depth exploration of dynamic cointegration-based pairs trading in cryptocurrency markets, based on research by Masood Tadi and Irina Kortchemski. The article covers advanced statistical models, profitability insights, and practical applications, with a focus on how Axon Trade supports traders in navigating dynamic markets.',
        keywords:
          'crypto arbitrage, dynamic cointegration, pairs trading strategy, cryptocurrency trading, statistical arbitrage, cointegration in crypto, crypto trading models, profitability in crypto, advanced trading strategies, axon trade blog, risk management in trading, crypto market insights, dynamic trading strategies, cointegration tests, arbitrage opportunities crypto, basket trading crypto, crypto trading, cryptocurrency',
        imgUrl: '/assets/blog-card-images/profitable-arbitrage-with-dynamic-cointegration.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'the-rise-of-ai-tokens',
    loadComponent: () =>
      import('./pages/blog/pages/2025/February/the-rise-of-ai-tokens/the-rise-of-ai-tokens.component').then(
        (m) => m.TheRiseOfAiTokensComponent
      ),
    data: {
      sitemap: {
        loc: 'the-rise-of-ai-tokens',
        lastmod: '2025-02-06',
      },
      meta: {
        loc: 'the-rise-of-ai-tokens',
        fileName: '/the-rise-of-ai-tokens',
        title: 'The Rise of AI Tokens and Their Impact on Digital Asset Trading',
        description:
          'AI tokens are reshaping blockchain ecosystems by integrating artificial intelligence with decentralized technologies. This article explores their applications, market trends, challenges, and how Axon Trade provides advanced tools to support traders in navigating the evolving digital asset landscape.',
        keywords:
          'crypto, cryptocurrency, crypto AI, AI tokens, Artificial intelligence tokens, Blockchain, AI-powered platforms, Decentralized marketplaces, Digital asset trading, Tokenized data marketplaces, Algorithmic trading tools, AI in DeFi, AI-driven trading strategies, Axon Trade, FIX API for trading, Real-time market data, Digital asset management, trading tools, AI token market, trading history, crypto trading',
        imgUrl: '/assets/blog-card-images/the-rise-of-ai-tokens.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'solana-and-sol-market',
    loadComponent: () =>
      import('./pages/blog/pages/2025/February/solana-and-sol-market/solana-and-sol-market.component').then(
        (m) => m.SolanaAndSolMarketComponent
      ),
    data: {
      sitemap: {
        loc: 'solana-and-sol-market',
        lastmod: '2025-02-04',
      },
      meta: {
        loc: 'solana-and-sol-market',
        fileName: '/solana-and-sol-market',
        title: 'Solana and SOL Market',
        description:
          'This article explores Solana\u0027s blockchain, focusing on its unique Proof of History mechanism and scalable architecture. It compares Solana with Bitcoin and Ethereum, providing insight into its increasing adoption among institutional investors. By examining Solana\u0027s tokenomics and market structure, the piece highlights the network\u0027s potential to address the scalability challenges faced by other blockchains, particularly in decentralized finance (DeFi) and digital assets.',
        keywords:
          'solana, solana blockchain, proof of history, solana tokenomics, decentralized finance, defi, solana scalability, solana vs bitcoin, solana vs ethereum, solana market, solana institutional, solana future, digital assets, solana speed, solana token, decentralized applications, solana transaction fees, solana ecosystem, solana network, solana financial applications, solana cryptocurrency, solana blockchain technology, solana defi, solana nft, solana growth, blockchain scalability, crypto market, solana adoption',
        imgUrl: '/assets/blog-card-images/solana-and-sol-market.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'market-volatility-and-its-implications-for-traders',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/January/market-volatility-and-its-implications-for-traders/market-volatility-and-its-implications-for-traders.component'
      ).then((m) => m.MarketVolatilityAndItsImplicationsForTradersComponent),
    data: {
      sitemap: {
        loc: 'market-volatility-and-its-implications-for-traders',
        lastmod: '2025-01-30',
      },
      meta: {
        loc: 'market-volatility-and-its-implications-for-traders',
        fileName: '/market-volatility-and-its-implications-for-traders',
        title: 'Market Volatility and Its Implications for Traders',
        description:
          'This article delves into the impact of market volatility on cryptocurrency trading, highlighting historical price trends of Bitcoin and altcoins, key volatility drivers, and technical indicators like RSI, Bollinger Bands, and Moving Averages. It also outlines strategies for identifying overbought and oversold conditions and managing risk effectively. Based on Kristian Ratia\u0027s research, the article provides valuable insights for traders aiming to navigate unpredictable market swings.',
        keywords:
          'crypto market volatility, Bitcoin price trends, altcoin volatility, cryptocurrency trading strategies, RSI indicator, Bollinger Bands crypto, moving averages crypto, overbought vs oversold, crypto risk management, crypto price swings, technical indicators, crypto trading tips, volatile markets, institutional crypto trading, retail crypto trading',
        imgUrl: '/assets/blog-card-images/market-volatility-and-its-implications-for-traders.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'advanced-algorithmic-trading-with-reinforcement-learning',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/January/advanced-algorithmic-trading-with-reinforcement-learning/advanced-algorithmic-trading-with-reinforcement-learning.component'
      ).then((m) => m.AdvancedAlgorithmicTradingWithReinforcementLearningComponent),
    data: {
      sitemap: {
        loc: 'advanced-algorithmic-trading-with-reinforcement-learning',
        lastmod: '2025-01-28',
      },
      meta: {
        loc: 'advanced-algorithmic-trading-with-reinforcement-learning',
        fileName: '/advanced-algorithmic-trading-with-reinforcement-learning',
        title: 'Advanced Algorithmic Trading with Reinforcement Learning',
        description:
          'Deep Reinforcement Learning (DRL) methods, particularly Deep Q-Networks (DQNs), have emerged as critical components in designing adaptive trading systems. This article explores the advancements in DQN-based trading algorithms, highlighting their performance improvements when augmented with advanced techniques such as Double DQN, Dueling DQN, Prioritized Experience Replay (PER), and Noisy Networks.',
        keywords:
          'algorithmic trading, reinforcement learning, prioritized experience replay, automated trading systems, cryptocurrency trading, portfolio optimization, machine learning in finance, stock trading algorithms, and high-frequency AI trading systems',
        imgUrl: '/assets/blog-card-images/advanced-algorithmic-trading-with-reinforcement-learning.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'how-blockchain-metrics-improve-cryptocurrency-trading-strategies',
    loadComponent: () =>
      import(
        './pages/blog/pages/2025/January/how-blockchain-metrics-improve-cryptocurrency-trading-strategies/how-blockchain-metrics-improve-cryptocurrency-trading-strategies.component'
      ).then((m) => m.HowBlockchainMetricsImproveCryptocurrencyTradingStrategiesComponent),
    data: {
      sitemap: {
        loc: 'how-blockchain-metrics-improve-cryptocurrency-trading-strategies',
        lastmod: '2025-01-22',
      },
      meta: {
        loc: 'how-blockchain-metrics-improve-cryptocurrency-trading-strategies',
        fileName: '/how-blockchain-metrics-improve-cryptocurrency-trading-strategies',
        title: 'How Blockchain Metrics Improve Cryptocurrency Trading Strategies',
        description:
          'Learn how blockchain metrics like hash rate, transaction costs, and wallet growth offer a data-driven approach to cryptocurrency trading. This article explores actionable strategies, highlighting how traders can integrate these insights for better decision-making and market analysis.',
        keywords:
          'cryptocurrency trading, blockchain metrics, hash rate, mining difficulty, transaction costs, wallet growth, trading strategies, data-driven trading, blockchain analysis, cryptocurrency tools, trading insights',
        imgUrl: '/assets/blog-card-images/how-blockchain-metrics-improve-cryptocurrency-trading-strategies.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'the-impact-of-fees-on-cryptocurrency-trading',
    loadComponent: () =>
      import(
        './pages/blog/pages/the-impact-of-fees-on-cryptocurrency-trading/the-impact-of-fees-on-cryptocurrency-trading.component'
      ).then((m) => m.TheImpactOfFeesOnCryptocurrencyTradingComponent),
    data: {
      sitemap: {
        loc: 'the-impact-of-fees-on-cryptocurrency-trading',
        lastmod: '2025-01-20',
      },
      meta: {
        loc: 'the-impact-of-fees-on-cryptocurrency-trading',
        fileName: '/the-impact-of-fees-on-cryptocurrency-trading',
        title: 'The Impact of Fees on Cryptocurrency Trading',
        description:
          'Cryptocurrency traders often overlook the significant impact of transaction fees on their overall profitability. This in-depth article explores how trading fees, including maker and taker fees, withdrawal costs, and network charges, can substantially influence trading outcomes. Drawing from Kristian Ratia\u0027s research, it provides a detailed analysis of how high-frequency strategies and technical indicators like Bollinger Bands, Moving Averages, and RSI interact with fee structures. The article also offers practical strategies to minimize costs and highlights the importance of fee-conscious trading for both institutional and retail participants.',
        keywords:
          'cryptocurrency trading fees, maker fees, taker fees, crypto withdrawal, network transaction fees, high-frequency crypto trading, low-frequency trading strategies, crypto trading profitability, Bollinger Bands analysis, SMA vs EMA in crypto, crypto market trends 2025, technical analysis in crypto trading, crypto trading strategies 2025, fee-conscious trading tips, cryptocurrency transaction costs, crypto exchange fees comparison, algorithmic crypto trading, institutional crypto trading strategies, retail crypto trading tips, crypto market volatility strategies',
        imgUrl: '/assets/blog-card-images/the-impact-of-fees-on-cryptocurrency-trading.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'from-prediction-to-profit',
    loadComponent: () =>
      import('./pages/blog/pages/from-prediction-to-profit/from-prediction-to-profit.component').then(
        (m) => m.FromPredictionToProfitComponent
      ),
    data: {
      sitemap: {
        loc: 'from-prediction-to-profit',
        lastmod: '2025-01-16',
      },
      meta: {
        loc: 'from-prediction-to-profit',
        fileName: '/from-prediction-to-profit',
        title: 'From Prediction to Profit\u2236 How Price Forecasting Drives Cryptocurrency Trading Strategies',
        description:
          'Cryptocurrency trading thrives on the ability to interpret market fluctuations and anticipate price trends. This article is based on the review titled From Prediction to Profit\u2236 A Comprehensive Review of Cryptocurrency Trading Strategies and Price Forecasting Techniques by Sattarov Otabek and Jaeyoung Choi (IEEE Access, 2024), which addresses the gap between price predictions and trading success. ',
        keywords:
          'cryptocurrency trading, price prediction, crypto, Bitcoin, Ethereum, Litecoin, trading models, machine learning, sentiment analysis, algorithmic trading, technical analysis, economic models, statistical models, BTC, ETH, LTC, Sharpe Ratio, trading strategies, market data',
        imgUrl: '/assets/blog-card-images/from-prediction-to-profit.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'role-of-deep-learning-in-modern-finance',
    loadComponent: () =>
      import(
        './pages/blog/pages/role-of-deep-learning-in-modern-finance/role-of-deep-learning-in-modern-finance.component'
      ).then((m) => m.RoleOfDeepLearningInModernFinanceComponent),
    data: {
      sitemap: {
        loc: 'role-of-deep-learning-in-modern-finance',
        lastmod: '2025-01-14',
      },
      meta: {
        loc: 'role-of-deep-learning-in-modern-finance',
        fileName: '/role-of-deep-learning-in-modern-finance',
        title: 'The Role of Deep Learning in Modern Finance',
        description:
          'An in-depth look at the integration of deep learning models such as RNNs, CNNs, and Transformers in finance. Learn how Explainable AI tools like SHAP and LIME ensure transparency in credit scoring, while GANs augment risk analysis and market scenario testing for portfolio optimization.',
        keywords:
          'crypto, crypto trading, algorithmic trading, portfolio manager, risk management, market data, automated trading, AI, ML, deep learning, AI in trading, portfolio management',
        imgUrl: '/assets/blog-card-images/role-of-deep-learning-in-modern-finance.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'crypto-hedge-funds-in-2025-key-forecasts-and-emerging-trends',
    loadComponent: () =>
      import(
        './pages/blog/pages/crypto-hedge-funds-in-2025-key-forecasts-and-emerging-trends/crypto-hedge-funds-in-2025-key-forecasts-and-emerging-trends.component'
      ).then((m) => m.CryptoHedgeFundsIn2025KeyForecastsAndEmergingTrendsComponent),
    data: {
      sitemap: {
        loc: 'crypto-hedge-funds-in-2025-key-forecasts-and-emerging-trends',
        lastmod: '2025-01-08',
      },
      meta: {
        loc: 'crypto-hedge-funds-in-2025-key-forecasts-and-emerging-trends',
        fileName: '/crypto-hedge-funds-in-2025-key-forecasts-and-emerging-trends',
        title: 'Crypto Hedge Funds in 2025\u2236 Key Forecasts and Emerging Trends',
        description:
          'Crypto hedge funds continue growing and adapting as they face new opportunities and challenges. As 2025 unfolds, this sector is expected to change significantly, influenced by regulations, technological progress, and shifting global markets. This article outlines key trends for the year and examines how these factors may shape institutional trading strategies.',
        keywords:
          'crypto hedge funds, digital asset hedge funds, institutional investment trends, derivatives trading in crypto, tokenization, spot Bitcoin ETFs, hedge fund strategies, crypto market, algo trading, hft, high-frequency trading',
        imgUrl: '/assets/blog-card-images/crypto-hedge-funds-in-2025-key-forecasts-and-emerging-trends.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'advanced-order-routing-techniques-for-multi-exchange-trading',
    loadComponent: () =>
      import(
        './pages/blog/pages/advanced-order-routing-techniques-for-multi-exchange-trading/advanced-order-routing-techniques-for-multi-exchange-trading.component'
      ).then((m) => m.AdvancedOrderRoutingTechniquesForMultiExchangeTradingComponent),
    data: {
      sitemap: {
        loc: 'advanced-order-routing-techniques-for-multi-exchange-trading',
        lastmod: '2025-01-06',
      },
      meta: {
        loc: 'advanced-order-routing-techniques-for-multi-exchange-trading',
        fileName: '/advanced-order-routing-techniques-for-multi-exchange-trading',
        title: 'Advanced Order Routing Techniques for Multi-Exchange Trading',
        description:
          'In digital asset markets, executing orders across various exchanges effectively can lead to better prices, lower costs, and broader liquidity access. Advanced Order Routing (AOR) methods have become essential for traders and institutions operating in fragmented markets. By applying algorithms and data-driven methods, AOR solutions make order management more precise and reliable.',
        keywords:
          'crypto, crypto trading, Advanced Order Routing, AOR, Smart Order Routers, SOR, liquidity aggregation, algorithmic execution, DeFi routing, crypto trading solutions, order management, digital asset execution, trading technology, decentralized exchanges, centralized exchanges, portfolio rebalancing, high-frequency trading',
        imgUrl: '/assets/blog-card-images/advanced-order-routing-techniques-for-multi-exchange-trading.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'crypto-hedge-fund-industry-retrospective-2024',
    loadComponent: () =>
      import(
        './pages/blog/pages/crypto-hedge-fund-industry-retrospective-2024/crypto-hedge-fund-industry-retrospective-2024.component'
      ).then((m) => m.CryptoHedgeFundIndustryRetrospective2024Component),
    data: {
      sitemap: {
        loc: 'crypto-hedge-fund-industry-retrospective-2024',
        lastmod: '2025-01-02',
      },
      meta: {
        loc: 'crypto-hedge-fund-industry-retrospective-2024',
        fileName: '/crypto-hedge-fund-industry-retrospective-2024',
        title: 'Crypto Hedge Fund Industry Retrospective\u2236 2024 Highlights',
        description:
          'A detailed examination of the crypto hedge fund industry in 2024, highlighting trends such as institutional adoption, the rise of derivatives trading, and the growing role of tokenization. The article explores differences in strategies, regulatory progress, and challenges faced by hedge funds operating in digital asset markets.',
        keywords:
          'crypto hedge funds, digital asset hedge funds, institutional investment trends, derivatives trading in crypto, tokenization, spot Bitcoin ETFs, hedge fund strategies, crypto market, algo trading, hft, high-frequency trading',
        imgUrl: '/assets/blog-card-images/crypto-hedge-fund-industry-retrospective-2024.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'axon-trade-and-whitebits-strategic-partnership',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-and-whitebits-strategic-partnership/axon-trade-and-whitebits-strategic-partnership.component'
      ).then((m) => m.AxonTradeAndWhitebitsStrategicPartnershipComponent),
    data: {
      sitemap: {
        loc: 'axon-trade-and-whitebits-strategic-partnership',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-and-whitebits-strategic-partnership',
        fileName: '/axon-trade-and-whitebits-strategic-partnership',
        title: 'Axon Trade and WhiteBIT Strategic Partnership',
        description:
          'Axon Trade partners with WhiteBIT to enhance the crypto trading experience, offering FIX API access, real-time data, and low-latency execution',
        keywords:
          'axon trade, whitebit, cryptocurrency exchange, fix api, market data, low-latency execution, institutional trading, crypto trading, high-frequency trading, hft, algotrading, crypto',
        imgUrl: '/assets/blog-card-images/axon-trade-and-whitebits-strategic-partnership.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer',
    loadComponent: () =>
      import(
        './pages/blog/pages/gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer/gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer.component'
      ).then((m) => m.GateIoAndAxonTradeStrategicCollaborationAndExclusiveOfferComponent),
    data: {
      sitemap: {
        loc: 'gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer',
        fileName: '/gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer',
        title: 'Gate.io and Axon Trade\u0027s Strategic Collaboration. and Exclusive Offer',
        description:
          'Gate.io partners with Axon Trade to enhance trading for professionals and institutions, offering FIX API, low-latency execution, and a 3-month zero-fee promotion with a free VPS',
        keywords:
          'Gate.io, cryptocurrency exchange, crypto trading, EMS, FIX API, real-time market data, order management, futures trading, margin trading, digital assets, VPS, HFT',
        imgUrl: '/assets/blog-card-images/gate-io-and-axon-trade-strategic-collaboration-and-exclusive-offer.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'axon-trade-and-gate-io-trading-campaign',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-and-gate-io-trading-campaign/axon-trade-and-gate-io-trading-campaign.component'
      ).then((m) => m.AxonTradeAndGateIoTradingCampaignComponent),
    data: {
      sitemap: {
        loc: 'axon-trade-and-gate-io-trading-campaign',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-and-gate-io-trading-campaign',
        fileName: '/axon-trade-and-gate-io-trading-campaign',
        title: 'Axon Trade x Gate.io Trading Campaign',
        description:
          'The Axon Trade and Gate.io Trading Campaign is already underway, offering users the chance to gain VIP5 status on Gate.io and earn up to $15,000 USDT in rewards.',
        keywords:
          'Axon Trade, Gate.io, VIP5, cryptocurrency exchange, crypto trading, FIX API, real-time market data, spot trading, futures trading, margin trading, digital assets, trading rewards, USDT',
        imgUrl: '/assets/blog-card-images/axon-trade-and-gate-io-trading-campaign.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'bitmart-partners-with-axon-trade',
    loadComponent: () =>
      import('./pages/blog/pages/bitmart-partners-with-axon-trade/bitmart-partners-with-axon-trade.component').then(
        (m) => m.BitmartPartnersWithAxonTradeComponent
      ),
    data: {
      sitemap: {
        loc: 'bitmart-partners-with-axon-trade',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'bitmart-partners-with-axon-trade',
        fileName: '/bitmart-partners-with-axon-trade',
        title: 'Axon Trade Partners with BitMart. Exclusive Offer and Advanced Trading Solutions',
        description:
          'Axon Trade partners with BitMart to offer free trading for three months, reduced fees, and a free VPS. Discover advanced trading tools, including EMS integration with real-time data and order management.',
        keywords:
          'BitMart, cryptocurrency exchange, crypto trading, EMS, FIX API, real-time market data, order management, staking, futures trading, margin trading, digital assets, VPS, HFT',
        imgUrl: '/assets/blog-card-images/bitmart-partners-with-axon-trade.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'optimization-techniques-for-fix-based-trading-applications',
    loadComponent: () =>
      import(
        './pages/blog/pages/optimization-techniques-for-fix-based-trading-applications/optimization-techniques-for-fix-based-trading-applications.component'
      ).then((m) => m.OptimizationTechniquesForFixBasedTradingApplicationsComponent),
    data: {
      sitemap: {
        loc: 'optimization-techniques-for-fix-based-trading-applications',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'optimization-techniques-for-fix-based-trading-applications',
        fileName: '/optimization-techniques-for-fix-based-trading-applications',
        title: 'Optimization techniques for FIX-based trading applications | Axon Trade',
        description: 'The article discusses optimization techniques for FIX-based trading applications',
        keywords:
          'fix api,market data,real time market data,fix api crypto,binance fix api,fix api for crypto trading,fix api for digital asset trading,industry',
        imgUrl: '/assets/blog-card-images/axon-tarde-optimization-techniques-for-FIX-based-trading-applications.png',
        og_type: 'article',
      },
    },
  },
  {
    path: 'how-to-build-your-next-trading-system-using-axon-trade-oems',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-to-build-your-next-trading-system-using-axon-trade-oems/how-to-build-your-next-trading-system-using-axon-trade-oems.component'
      ).then((m) => m.HowToBuildYourNextTradingSystemUsingAxonTradeOemsComponent),
    data: {
      sitemap: {
        loc: 'how-to-build-your-next-trading-system-using-axon-trade-oems',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'how-to-build-your-next-trading-system-using-axon-trade-oems',
        fileName: '/how-to-build-your-next-trading-system-using-axon-trade-oems',
        title: 'How to build your next trading system using Axon Trade OEMS | Axon Trade',
        description:
          'We are pleased to invite you to our upcoming webinar How to build your next trading system using Axon Trade OEMS. We will guide you through the extensive functionality of our FIX API for trading and market data, as well as provide an in-depth explanation on how to seamlessly integrate with our Management REST API. We look forward to sharing our knowledge and insights with you.',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-tarde-how-to-build-your-next-trading-system-using-Axon-Trade-OEMS.png',
      },
    },
  },
  {
    path: 'axon-trade-oems-is-now-available-in-equinix-hk2',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-oems-is-now-available-in-equinix-hk2/axon-trade-oems-is-now-available-in-equinix-hk2.component'
      ).then((m) => m.AxonTradeOemsIsNowAvailableInEquinixHk2Component),
    data: {
      sitemap: {
        loc: 'axon-trade-oems-is-now-available-in-equinix-hk2',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-oems-is-now-available-in-equinix-hk2',
        fileName: '/axon-trade-oems-is-now-available-in-equinix-hk2',
        title: 'Axon Trade OEMS is Now Available in Equinix HK2 | | Axon Trade',
        description:
          'We are happy to announce that Axon Trade\u0027s OEMS for digital asset trading is now available in the Equinix HK2 location – Hong Kong! We have connectors with 30+ exchanges, including these ones\u2236 BinanceBybitOKXHuobi Global',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-OEMS-New-Point-of-Presence-equinix-hk2.png',
      },
    },
  },
  {
    path: 'api-updates-january-2023',
    loadComponent: () =>
      import('./pages/blog/pages/api-updates-january-2023/api-updates-january-2023.component').then(
        (m) => m.ApiUpdatesJanuary2023Component
      ),
    data: {
      sitemap: {
        loc: 'api-updates-january-2023',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'api-updates-january-2023',
        fileName: '/api-updates-january-2023',
        title: 'XTRD | FIX API | Updates | Axon Trade',
        description:
          'January 2023 FIX API updates - universal Order Cancel/Replace Request and ability to fetch exchange statuses using TradingSessionStatusRequest message',
        keywords:
          'crypto oems, crypto fix api, fix api, crypto exchange fix api, crypto trading, algotrading, crypto hft, high frequency trading crypto',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-API-updates-january-2023.png',
      },
    },
  },
  {
    path: 'bjf-trading-group-partners-with-axon-trade',
    loadComponent: () =>
      import(
        './pages/blog/pages/bjf-trading-group-partners-with-axon-trade/bjf-trading-group-partners-with-axon-trade.component'
      ).then((m) => m.BjfTradingGroupPartnersWithAxonTradeComponent),
    data: {
      sitemap: {
        loc: 'bjf-trading-group-partners-with-axon-trade',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'bjf-trading-group-partners-with-axon-trade',
        fileName: '/bjf-trading-group-partners-with-axon-trade',
        title: 'BJF Trading Group Partners With Axon Trade | | Axon Trade',
        description:
          'FX Software development company BJF Trading Group, announces today its partnership with Axon Trade, OEMS for digital asset trading. This partnership will allow customers to use sophisticated trading algorithms created by BJF Trading Group, across the entire spectrum of cryptocurrency exchanges supported by the Axon Trade OEMS. &quot;Digital asset space is still full of different inefficiencies that might be exploited to gain a certain profit. Of course, there is no free lunch, and to get positive results, you must be properly equipped and educated. BJF Trading Group did tremendous work discovering small operational nuances of traditional and crypto markets and turning them into algos.Axon Trade provides you with the tools that you can use to &quot;test the digital water&quot;, said Serg Gulko, Co-Founder and CEO at Axon Trade. Founded in 2000, BJF Trading group is a boutique Forex and Cryptocurrencies software development company that provides solutions for FX trading, specializing in Expert',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-bjf-trading-group.png',
      },
    },
  },
  {
    path: 'lessons-after-ftx-collapse',
    loadComponent: () =>
      import('./pages/blog/pages/lessons-after-ftx-collapse/lessons-after-ftx-collapse.component').then(
        (m) => m.LessonsAfterFtxCollapseComponent
      ),
    data: {
      sitemap: {
        loc: 'lessons-after-ftx-collapse',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'lessons-after-ftx-collapse',
        fileName: '/lessons-after-ftx-collapse',
        title: 'Lessons after FTX collapse | | Axon Trade',
        description:
          'By Axon Trade\u0027s CEO Serg Gulko With FTX collapse, you might notice a growing number of voices that DEFI is a solution. It is not. You\u0027ll replace one risk with another. A balanced approach - that\u0027s the answer. Never put all eggs into a single basket.Rule of thumb on reducing risks with crypto - check for interconnections. By trading on FTX using the money you borrow on BlockFi you expose yourself to potential chain effects.If you don\u0027t have the time/money/willpower to do proper due diligence, one of the solutions is to use brokers (I never thought I\u0027d say something like that...) instead of direct market access. But if you do have time, resources, and willpower to invest in robust trading infrastructure that helps you to diversify and manage risks - consider using Axon Trade OEMS. Do not lock yourself with other FTXs.',
        keywords: 'industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/lessons-after-FTX-collapce.png',
      },
    },
  },
  {
    path: 'latency-arbitrage-on-crypto-markets-using-axon-trade-oems',
    loadComponent: () =>
      import(
        './pages/blog/pages/latency-arbitrage-on-crypto-markets-using-axon-trade-oems/latency-arbitrage-on-crypto-markets-using-axon-trade-oems.component'
      ).then((m) => m.LatencyArbitrageOnCryptoMarketsUsingAxonTradeOemsComponent),
    data: {
      sitemap: {
        loc: 'latency-arbitrage-on-crypto-markets-using-axon-trade-oems',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'latency-arbitrage-on-crypto-markets-using-axon-trade-oems',
        fileName: '/latency-arbitrage-on-crypto-markets-using-axon-trade-oems',
        title: 'Latency arbitrage on crypto markets using Axon Trade OEMS | Axon Trade',
        description:
          'The article describes one of Axon Trade\u0027s side projects - a latency arbitrage trading system.',
        keywords: 'crypto latency arb,crypto latency arbitrage,latency arb trading,industry,low-latency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/latency-arbitrage-on-crypto-markets-using-axon-trade-oems.png',
      },
    },
  },
  {
    path: 'axon-trade-to-integrate-with-brokeree-solutions',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-to-integrate-with-brokeree-solutions/axon-trade-to-integrate-with-brokeree-solutions.component'
      ).then((m) => m.AxonTradeToIntegrateWithBrokereeSolutionsComponent),
    data: {
      sitemap: {
        loc: 'axon-trade-to-integrate-with-brokeree-solutions',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-to-integrate-with-brokeree-solutions',
        fileName: '/axon-trade-to-integrate-with-brokeree-solutions',
        title: 'MT4/MT4 Crypto bridge by Brokeree/XTRD | Axon Trade',
        description:
          'Brokeree Solutions, a turnkey solutions developer for multi-asset brokers, has just announced the integration of its flagship Liquidity Bridge with Axon Trade – orders and execution management system (OEMS) for digital asset trading with a strong focus on low-latency and high-throughput execution. Multi-server Liquidity Bridge is a comprehensive solution that combines advanced A/B book order execution, smart liquidity aggregation, and risk management. With this integration, brokers may introduce new crypto instruments for their clients on MetaTrader 4 and 5 platforms via the market data and liquidity provided by Axon Trade. &quot;We provide access to major US, Asia, and Europe exchanges. By leveraging our stack, clients can easily increase their liquidity offering by adding new LPs from the management panel without spending weeks for integration followed by neverending maintenance,&quot; said Serg Gulko, CEO of Axon Trade. &quot;I came to the digital asset space from FX and am familiar with the',
        keywords: 'mt4 crypto,mt5 crypto,metaquotes crypto,mt4 bridge for crypto',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-brokeree.png',
      },
    },
  },
  {
    path: 'kucoin-is-now-available-through-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/kucoin-is-now-available-through-axon-trade-fix-api/kucoin-is-now-available-through-axon-trade-fix-api.component'
      ).then((m) => m.KucoinIsNowAvailableThroughAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'kucoin-is-now-available-through-axon-trade-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'kucoin-is-now-available-through-axon-trade-fix-api',
        fileName: '/kucoin-is-now-available-through-axon-trade-fix-api',
        title: 'KuCoin is now available through Axon Trade | Axon Trade',
        description:
          'Axon Trade OEMS for digital assets trading allows receiving normalized market data and actively trading on KuCoin\u0027s markets using unified FIX 4.4 end-point. ',
        keywords: 'kucoin fix api,kucoin trading api,kucoin hft',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-kucoin.png',
      },
    },
  },
  {
    path: 'bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors',
    loadComponent: () =>
      import(
        './pages/blog/pages/bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors/bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors.component'
      ).then((m) => m.BitgetPartnersWithAxonTradeToOfferSeamlessTradingCorridorForInstitutionalInvestorsComponent),
    data: {
      sitemap: {
        loc: 'bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors',
        fileName: '/bitget-partners-with-axon-trade-to-offer-seamless-trading-corridor-for-institutional-investors',
        title: 'Bitget partners with Axon Trade | Axon Trade',
        description: 'Bitget partners with Axon Trade to offer seamless trading corridor for institutional investors',
        keywords: 'bitget fix api,bitget trading api,bitget algo trading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-bitget.png',
      },
    },
  },
  {
    path: 'whitebit-is-now-available-through-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/whitebit-is-now-available-through-axon-trade-fix-api/whitebit-is-now-available-through-axon-trade-fix-api.component'
      ).then((m) => m.WhitebitIsNowAvailableThroughAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'whitebit-is-now-available-through-axon-trade-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'whitebit-is-now-available-through-axon-trade-fix-api',
        fileName: '/whitebit-is-now-available-through-axon-trade-fix-api',
        title: 'WhiteBIT is now available through Axon Trade FIX API | Axon Trade',
        description:
          'Axon Trade OEMS for digital assets trading allows receiving normalized market data and actively trading on WhiteBit\u0027s markets using unified FIX 4.4 end-point.',
        keywords: 'fix api,whitebit,crypto,cryptocurrency,crypto trading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-whitebit.png',
      },
    },
  },
  {
    path: 'coinflex-is-now-available-through-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/coinflex-is-now-available-through-axon-trade-fix-api/coinflex-is-now-available-through-axon-trade-fix-api.component'
      ).then((m) => m.CoinflexIsNowAvailableThroughAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'coinflex-is-now-available-through-axon-trade-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'coinflex-is-now-available-through-axon-trade-fix-api',
        fileName: '/coinflex-is-now-available-through-axon-trade-fix-api',
        title: 'CoinFLEX is now available through Axon Trade FIX API | Axon Trade',
        description:
          'We are happy to announce that from now CoinFLEX is available through our OEMS. Both spot and derivatives markets can be accessed using the same FIX 4.4. API',
        keywords: 'fix api,crypto,cryptocurrency,derivatives,trading,crypto trading,coinflex',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-coinflex.png',
      },
    },
  },
  {
    path: 'axon-trade-opens-up-in-ty8-data-center',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-opens-up-in-ty8-data-center/axon-trade-opens-up-in-ty8-data-center.component'
      ).then((m) => m.AxonTradeOpensUpInTy8DataCenterComponent),
    data: {
      sitemap: {
        loc: 'axon-trade-opens-up-in-ty8-data-center',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-opens-up-in-ty8-data-center',
        fileName: '/axon-trade-opens-up-in-ty8-data-center',
        title: 'Axon Trade Opens Up in TY8 Data Center | Axon Trade',
        description:
          'Axon Trade\u0027s OEMS for digital asset trading is now available in the Equinix TY8 location - Tokyo, Japan',
        keywords: 'oems for crypto,oems,crypto trading,ty8,ty8 data center',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-ty8-data-center.png',
      },
    },
  },
  {
    path: 'axon-trade-oems-for-crypto',
    loadComponent: () =>
      import('./pages/blog/pages/axon-trade-oems-for-crypto/axon-trade-oems-for-crypto.component').then(
        (m) => m.AxonTradeOemsForCryptoComponent
      ),
    data: {
      sitemap: {
        loc: 'axon-trade-oems-for-crypto',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-oems-for-crypto',
        fileName: '/axon-trade-oems-for-crypto',
        title: 'Axon Trade OEMS for Crypto | Axon Trade',
        description:
          'This article addresses the most common questions we received about Axon Trade OEMS for digital assets trading.',
        keywords: 'crypto,cryptocurrency,digital assets,crypto trading,oems,hft,high frequency trading,algotrading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-oems-for-crypto.png',
      },
    },
  },
  {
    path: 'renko-based-scalper-for-binance-using-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/renko-based-scalper-for-binance-using-axon-trade-fix-api/renko-based-scalper-for-binance-using-axon-trade-fix-api.component'
      ).then((m) => m.RenkoBasedScalperForBinanceUsingAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'renko-based-scalper-for-binance-using-axon-trade-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'renko-based-scalper-for-binance-using-axon-trade-fix-api',
        fileName: '/renko-based-scalper-for-binance-using-axon-trade-fix-api',
        title: 'Scalper for Binance Using Axon Trade FIX API | Axon Trade',
        description: 'This article is an instruction on how to build a scalping application that trades on Binance.',
        keywords: 'binance,crypto trading,renko,fix api,crypto,cryptocurrency,algotrading,algo trading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-binance-fix-api.png',
      },
    },
  },
  {
    path: 'how-to-use-axon-trade-fix-api-from-javascript-node-js',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-to-use-axon-trade-fix-api-from-javascript-node-js/how-to-use-axon-trade-fix-api-from-javascript-node-js.component'
      ).then((m) => m.HowToUseAxonTradeFixApiFromJavascriptNodeJsComponent),
    data: {
      sitemap: {
        loc: 'how-to-use-axon-trade-fix-api-from-javascript-node-js',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'how-to-use-axon-trade-fix-api-from-javascript-node-js',
        fileName: '/how-to-use-axon-trade-fix-api-from-javascript-node-js',
        title: 'How to use Axon Trade FIX API from JavaScript/node.js | Axon Trade',
        description: 'A small demo on how to use Axon Trade FIX API from JavaScript/node.js.',
        keywords: 'fix api,javascript,crypto trading,algo trading,algotrading,crypto,cryptocurrency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/how-to-use-xtrd-fix-api-from-javascript.png',
      },
    },
  },
  {
    path: 'bybit-is-now-available-through-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/bybit-is-now-available-through-axon-trade-fix-api/bybit-is-now-available-through-axon-trade-fix-api.component'
      ).then((m) => m.BybitIsNowAvailableThroughAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'bybit-is-now-available-through-axon-trade-fix-api',
        lastmod: '2025-02-07',
      },
      meta: {
        loc: 'bybit-is-now-available-through-axon-trade-fix-api',
        fileName: '/bybit-is-now-available-through-axon-trade-fix-api',
        title: 'Bybit is now available through Axon Trade FIX API | Axon Trade',
        description: 'Bybit is available through Axon Trade FIX API.',
        keywords: 'crypto,crypto trading,bybit,fix api',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-bybit.png',
      },
    },
  },
  {
    path: 'changes-in-naming-convention-for-perpetual-swaps-futures-and-options',
    loadComponent: () =>
      import(
        './pages/blog/pages/changes-in-naming-convention-for-perpetual-swaps-futures-and-options/changes-in-naming-convention-for-perpetual-swaps-futures-and-options.component'
      ).then((m) => m.ChangesInNamingConventionForPerpetualSwapsFuturesAndOptionsComponent),
    data: {
      sitemap: {
        loc: 'changes-in-naming-convention-for-perpetual-swaps-futures-and-options',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'changes-in-naming-convention-for-perpetual-swaps-futures-and-options',
        fileName: '/changes-in-naming-convention-for-perpetual-swaps-futures-and-options',
        title: 'Changes in Naming For Futures, and Options | Axon Trade',
        description: 'We changed the naming convention for perpetual swaps, futures, and options.',
        keywords: 'perpetual swaps,futures,options,crypto,crypto trading',
        og_type: 'article',
        imgUrl:
          '/assets/blog-card-images/axon-trade-changes-in-naming-convention-for-perpetual-swaps-futures-and-options.png',
      },
    },
  },
  {
    path: 'how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving/how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving.component'
      ).then((m) => m.HowToImproveExecutionInCryptoMarketsOrAxonTradeArtOfShavingComponent),
    data: {
      sitemap: {
        loc: 'how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving',
        fileName: '/how-to-improve-execution-in-crypto-markets-or-axon-trade-art-of-shaving',
        title: 'How to Improve Execution in Crypto Markets | Axon Trade',
        description:
          'An article by Axon Trade\u0027s Co-Founder and CTO Serg Gulko on improving execution in crypto markets.',
        keywords: 'high-frequency trading,crypto trading,crypto,cryptocurrency,low-latency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-how-to-improve-execution-in-crypto-markets.png',
      },
    },
  },
  {
    path: 'axon-trade-opens-up-in-ld4-data-center',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-opens-up-in-ld4-data-center/axon-trade-opens-up-in-ld4-data-center.component'
      ).then((m) => m.AxonTradeOpensUpInLd4DataCenterComponent),
    data: {
      sitemap: {
        loc: 'axon-trade-opens-up-in-ld4-data-center',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-opens-up-in-ld4-data-center',
        fileName: '/axon-trade-opens-up-in-ld4-data-center',
        title: 'Axon Trade Opens Up in LD4 Data Center | Axon Trade',
        description:
          'We are happy to announce that Axon Trade\u0027s OEMS for digital asset trading is now available in the Equinix LD4 location - London, UK!',
        keywords: 'oems,digital assets,crypto trading,data center,crypto',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-opens-up-in-ld-4-data-center.png',
      },
    },
  },
  {
    path: 'top-5-quant-trading-and-algotrading-blogs',
    loadComponent: () =>
      import(
        './pages/blog/pages/top-5-quant-trading-and-algotrading-blogs/top-5-quant-trading-and-algotrading-blogs.component'
      ).then((m) => m.Top5QuantTradingAndAlgotradingBlogsComponent),
    data: {
      sitemap: {
        loc: 'top-5-quant-trading-and-algotrading-blogs',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'top-5-quant-trading-and-algotrading-blogs',
        fileName: '/top-5-quant-trading-and-algotrading-blogs',
        title: 'Top 5 Quant Trading and Algotrading Blogs | Axon Trade',
        description:
          'Top 5 Quant Trading and Algotrading Blogs\u2236 QuantInsti, Hackernoon, Quantpedia, Quantifiable Edges, Alvarez Quant Trading.',
        keywords: 'algo trading, algotrading, quant trading, crypto, crypto trading, industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/top-quant-trading-and-algotrading-blogs.png',
      },
    },
  },
  {
    path: 'coinbase-pro-api-review',
    loadComponent: () =>
      import('./pages/blog/pages/coinbase-pro-api-review/coinbase-pro-api-review.component').then(
        (m) => m.CoinbaseProApiReviewComponent
      ),
    data: {
      sitemap: {
        loc: 'coinbase-pro-api-review',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'coinbase-pro-api-review',
        fileName: '/coinbase-pro-api-review',
        title: 'Coinbase Pro API Review | Axon Trade',
        description: 'Coinbase Pro API Review by Axon Trade\u0027s Co-Founder and CTO Serg Gulko',
        keywords: 'coinbase,api,crypto,crypto trading,crypto exchange',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/coinbase-pro-api-overview.png',
      },
    },
  },
  {
    path: 'different-deployment-options',
    loadComponent: () =>
      import('./pages/blog/pages/different-deployment-options/different-deployment-options.component').then(
        (m) => m.DifferentDeploymentOptionsComponent
      ),
    data: {
      sitemap: {
        loc: 'different-deployment-options',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'different-deployment-options',
        fileName: '/different-deployment-options',
        title: 'Different deployment options | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236 Different deployment options',
        keywords: 'crypto,crypto trading,digital assets,colocation,cross-connection,vpn,vps',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-different-deployment-options.png',
      },
    },
  },
  {
    path: 'engineering-axon-trade-fort-knox-of-authentication',
    loadComponent: () =>
      import(
        './pages/blog/pages/engineering-axon-trade-fort-knox-of-authentication/engineering-axon-trade-fort-knox-of-authentication.component'
      ).then((m) => m.EngineeringAxonTradeFortKnoxOfAuthenticationComponent),
    data: {
      sitemap: {
        loc: 'engineering-axon-trade-fort-knox-of-authentication',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'engineering-axon-trade-fort-knox-of-authentication',
        fileName: '/engineering-axon-trade-fort-knox-of-authentication',
        title: 'Axon Trade\u0027s Fort Knox of Authentication | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236 Engineering Axon Trade\u0027s Fort Knox of Authentication',
        keywords: 'security,crypto,crypto trading,digital assets',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-fort-knox-of-authentification.png',
      },
    },
  },
  {
    path: 'normalized-api-for-trading',
    loadComponent: () =>
      import('./pages/blog/pages/normalized-api-for-trading/normalized-api-for-trading.component').then(
        (m) => m.NormalizedApiForTradingComponent
      ),
    data: {
      sitemap: {
        loc: 'normalized-api-for-trading',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'normalized-api-for-trading',
        fileName: '/normalized-api-for-trading',
        title: 'Normalized API for trading | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236 Normalized API for trading',
        keywords: 'api,crypto,crypto trading,crypto exchanges,digital assets',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-normalized-api-for-trading.png',
      },
    },
  },
  {
    path: 'normalized-symbology',
    loadComponent: () =>
      import('./pages/blog/pages/normalized-symbology/normalized-symbology.component').then(
        (m) => m.NormalizedSymbologyComponent
      ),
    data: {
      sitemap: {
        loc: 'normalized-symbology',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'normalized-symbology',
        fileName: '/normalized-symbology',
        title: 'Normalized symbology | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236  Normalized symbology',
        keywords: 'market data,crypto,cryptocurrency,crypto trading,digital assets',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-normalized-symbology.png',
      },
    },
  },
  {
    path: 'reliable-market-data',
    loadComponent: () =>
      import('./pages/blog/pages/reliable-market-data/reliable-market-data.component').then(
        (m) => m.ReliableMarketDataComponent
      ),
    data: {
      sitemap: {
        loc: 'reliable-market-data',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'reliable-market-data',
        fileName: '/reliable-market-data',
        title: 'Reliable Market Data | Axon Trade',
        description: '5 Reasons to Use Axon Trade\u2236  Reliable Market Data',
        keywords: 'market data,crypto,crypto trading,digital assets,cryptocurrency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-to-use-axon-trade-reliable-market-data.png',
      },
    },
  },
  {
    path: 'how-to-trade-on-huobi-using-axon-trade-fix-api',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-to-trade-on-huobi-using-axon-trade-fix-api/how-to-trade-on-huobi-using-axon-trade-fix-api.component'
      ).then((m) => m.HowToTradeOnHuobiUsingAxonTradeFixApiComponent),
    data: {
      sitemap: {
        loc: 'how-to-trade-on-huobi-using-axon-trade-fix-api',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'how-to-trade-on-huobi-using-axon-trade-fix-api',
        fileName: '/how-to-trade-on-huobi-using-axon-trade-fix-api',
        title: 'How to trade on Huobi using Axon Trade FIX API | Axon Trade',
        description: 'How to trade on Huobi using Axon Trade FIX API',
        keywords: 'huobi,crypto,cryptocurrency,crypto trading,digital assets',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-huobi-fix-api.png',
      },
    },
  },
  {
    path: 'recap-after-europe-trading-show-2019',
    loadComponent: () =>
      import(
        './pages/blog/pages/recap-after-europe-trading-show-2019/recap-after-europe-trading-show-2019.component'
      ).then((m) => m.RecapAfterEuropeTradingShow2019Component),
    data: {
      sitemap: {
        loc: 'recap-after-europe-trading-show-2019',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'recap-after-europe-trading-show-2019',
        fileName: '/recap-after-europe-trading-show-2019',
        title: 'Recap after Europe Trading Show 2019 | Axon Trade',
        description: 'For Axon Trade, the Trading Show has become a must-attend event. This year we visited London!',
        keywords: 'trading show,crypto trading,crypto exchanges',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-recap-after-europe-trading-show-2019.png',
      },
    },
  },
  {
    path: 'the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019',
    loadComponent: () =>
      import(
        './pages/blog/pages/the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019/the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019.component'
      ).then((m) => m.TheAxonTradeTeamWillTakePartInTheTradingShowEurope2019Component),
    data: {
      sitemap: {
        loc: 'the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019',
        fileName: '/the-axon-trade-team-will-take-part-in-the-trading-show-europe-2019',
        title: 'The Axon Trade team will take part in Trading Show Europe | Axon Trade',
        description:
          'The Axon Trade team will take part in The Trading Show in London on October 17, 2019 as an exhibitor and sponsor.',
        keywords: 'trading show,crypto trading,exchanges,crypto exchanges',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-trading-show-2020.png',
      },
    },
  },
  {
    path: '5-reasons-why-we-need-more-interconnected-crypto-exchanges',
    loadComponent: () =>
      import(
        './pages/blog/pages/five-reasons-why-we-need-more-interconnected-crypto-exchanges/five-reasons-why-we-need-more-interconnected-crypto-exchanges.component'
      ).then((m) => m.FiveReasonsWhyWeNeedMoreInterconnectedCryptoExchangesComponent),
    data: {
      sitemap: {
        loc: '5-reasons-why-we-need-more-interconnected-crypto-exchanges',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: '5-reasons-why-we-need-more-interconnected-crypto-exchanges',
        fileName: '/5-reasons-why-we-need-more-interconnected-crypto-exchanges',
        title: 'Why We Need More Interconnected Crypto Exchanges | Axon Trade',
        description: '5 Reasons Why We Need More Interconnected Crypto Exchanges',
        keywords: 'crypto exchanges,cryptocurrency,institutional traders,liquidity,industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/5-reasons-why-we-need-more-interconnected-crypto-exchanges.png',
      },
    },
  },
  {
    path: '6-factors-feared-by-institutional-investors-in-cryptocurrency',
    loadComponent: () =>
      import(
        './pages/blog/pages/six-factors-feared-by-institutional-investors-in-cryptocurrency/six-factors-feared-by-institutional-investors-in-cryptocurrency.component'
      ).then((m) => m.SixFactorsFearedByInstitutionalInvestorsInCryptocurrencyComponent),
    data: {
      sitemap: {
        loc: '6-factors-feared-by-institutional-investors-in-cryptocurrency',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: '6-factors-feared-by-institutional-investors-in-cryptocurrency',
        fileName: '/6-factors-feared-by-institutional-investors-in-cryptocurrency',
        title: '6 Factors Feared by Institutional Investors in Crypto | Axon Trade',
        description: 'Six factors affecting the appetite of institutional investors in cryptocurrency.',
        keywords: 'institutional crypto traders,institutional investors,crypto traders,banks,industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/6-factors-feared-by-institutional-investors-in-cryptocurrency.png',
      },
    },
  },
  {
    path: 'axon-trade-team-will-take-part-in-the-trading-show-new-york-2019',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-team-will-take-part-in-the-trading-show-new-york-2019/axon-trade-team-will-take-part-in-the-trading-show-new-york-2019.component'
      ).then((m) => m.AxonTradeTeamWillTakePartInTheTradingShowNewYork2019Component),
    data: {
      sitemap: {
        loc: 'axon-trade-team-will-take-part-in-the-trading-show-new-york-2019',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-team-will-take-part-in-the-trading-show-new-york-2019',
        fileName: '/axon-trade-team-will-take-part-in-the-trading-show-new-york-2019',
        title: 'The Axon Trade team will take part in Trading Show New York | Axon Trade',
        description:
          'The Axon Trade team will take part in The Trading Show in New York on September 25, 2019 as an exhibitor and sponsor.',
        keywords: 'trading show,crypto trading,crypto exchanges,exchange technology',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-trading-show-2019.png',
      },
    },
  },
  {
    path: 'how-institutional-traders-would-benefit-from-global-crypto-exchanges',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-institutional-traders-would-benefit-from-global-crypto-exchanges/how-institutional-traders-would-benefit-from-global-crypto-exchanges.component'
      ).then((m) => m.HowInstitutionalTradersWouldBenefitFromGlobalCryptoExchangesComponent),
    data: {
      sitemap: {
        loc: 'how-institutional-traders-would-benefit-from-global-crypto-exchanges',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'how-institutional-traders-would-benefit-from-global-crypto-exchanges',
        fileName: '/how-institutional-traders-would-benefit-from-global-crypto-exchanges',
        title: 'Institutional Traders and Global Crypto Exchanges | Axon Trade',
        description:
          'Axon Trade is building new financial products to improve access to global crypto exchanges for institutional investors',
        keywords: 'exchange,cryptocurrency,crypto,traders,institutional traders,industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/how-institutional-traders-would-benefit-from-global-crypto-exchanges.png',
      },
    },
  },
  {
    path: 'axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services/axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services.component'
      ).then(
        (m) => m.AxonTradeAndCexIoAnnounceIntegrationPartnershipOfferingInstitutionalGradeTradingServicesComponent
      ),
    data: {
      sitemap: {
        loc: 'axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services',
        fileName:
          '/axon-trade-and-cex-io-announce-integration-partnership-offering-institutional-grade-trading-services',
        title: 'XTRD and CEX.IO Institutional-Grade Trading Services | Axon Trade',
        description:
          'The partnership will integrate existing and future XTRD trading products with CEX.IO and create a CEX.IO institutional services division',
        keywords: 'institutional crypto traders,institutional investors,cryptocurrency,bitcoin',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-cex.io-partnership.png',
      },
    },
  },
  {
    path: 'how-institutional-investment-will-change-the-crypto-asset-market',
    loadComponent: () =>
      import(
        './pages/blog/pages/how-institutional-investment-will-change-the-crypto-asset-market/how-institutional-investment-will-change-the-crypto-asset-market.component'
      ).then((m) => m.HowInstitutionalInvestmentWillChangeTheCryptoAssetMarketComponent),
    data: {
      sitemap: {
        loc: 'how-institutional-investment-will-change-the-crypto-asset-market',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'how-institutional-investment-will-change-the-crypto-asset-market',
        fileName: '/how-institutional-investment-will-change-the-crypto-asset-market',
        title: 'How Institutional Investment Will Change the Crypto Market | Axon Trade',
        description: 'The good and bad of institutional investment in the crypto market.',
        keywords: 'institutional investment,crypto,blockchain,institutional traders,cryptocurrency',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-how-to-improve-execution-in-crypto-asset-markets.png',
      },
    },
  },
  {
    path: 'rest-ws-vs-fix-for-crypto-trading',
    loadComponent: () =>
      import('./pages/blog/pages/rest-ws-vs-fix-for-crypto-trading/rest-ws-vs-fix-for-crypto-trading.component').then(
        (m) => m.RestWsVsFixForCryptoTradingComponent
      ),
    data: {
      sitemap: {
        loc: 'rest-ws-vs-fix-for-crypto-trading',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'rest-ws-vs-fix-for-crypto-trading',
        fileName: '/rest-ws-vs-fix-for-crypto-trading',
        title: 'REST/Ws vs FIX for crypto trading | Axon Trade',
        description: 'Axon Trade CTO Serg Gulko on differences between REST/Ws and FIX for crypto trading',
        keywords: 'rest api,ws,fix api,crypto trading,industry',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/REST-WS-vs-FIX-for-Crypto-Trading.png',
      },
    },
  },
  {
    path: 'axon-trade-update-october-31-2018',
    loadComponent: () =>
      import('./pages/blog/pages/axon-trade-update-october-31-2018/axon-trade-update-october-31-2018.component').then(
        (m) => m.AxonTradeUpdateOctober312018Component
      ),
    data: {
      sitemap: {
        loc: 'axon-trade-update-october-31-2018',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-update-october-31-2018',
        fileName: '/axon-trade-update-october-31-2018',
        title: 'XTRD Update\u2236 October 31, 2018 | Axon Trade',
        description:
          'Summary\u2236 Product update, New York Trading Show recap, VOA\u0027s Interview with XTRD CTO Serg Gulko, Miami Blockchain Shift Update, Weekly CTO Q&A sessions',
        keywords: 'trading show,product update,crypto trading',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-product-update-october-2018.png',
      },
    },
  },
  {
    path: 'blockchain-shift-conference-update',
    loadComponent: () =>
      import('./pages/blog/pages/blockchain-shift-conference-update/blockchain-shift-conference-update.component').then(
        (m) => m.BlockchainShiftConferenceUpdateComponent
      ),
    data: {
      sitemap: {
        loc: 'blockchain-shift-conference-update',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'blockchain-shift-conference-update',
        fileName: '/blockchain-shift-conference-update',
        title: 'Blockchain Shift Conference Update | Axon Trade',
        description: 'Axon Trade recently attended the Miami Blockchain Shift conference on October 11–12',
        keywords: 'institutional traders,distributed ledger technology,fintech,events,videos',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-blockchain-shift-conference-update.png',
      },
    },
  },
  {
    path: 'new-york-trading-show-recap',
    loadComponent: () =>
      import('./pages/blog/pages/new-york-trading-show-recap/new-york-trading-show-recap.component').then(
        (m) => m.NewYorkTradingShowRecapComponent
      ),
    data: {
      sitemap: {
        loc: 'new-york-trading-show-recap',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'new-york-trading-show-recap',
        fileName: '/new-york-trading-show-recap',
        title: 'New York Trading Show recap | Axon Trade',
        description: 'Axon Trade Attended The New York Trading Show 2018',
        keywords: 'trading show,crypto,crypto trading,cryptocurrency,events',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-new-york-trading-show-recap.png',
      },
    },
  },
  {
    path: 'axon-trade-product-update-october-11-2018',
    loadComponent: () =>
      import(
        './pages/blog/pages/axon-trade-product-update-october-11-2018/axon-trade-product-update-october-11-2018.component'
      ).then((m) => m.AxonTradeProductUpdateOctober112018Component),
    data: {
      sitemap: {
        loc: 'axon-trade-product-update-october-11-2018',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'axon-trade-product-update-october-11-2018',
        fileName: '/axon-trade-product-update-october-11-2018',
        title: 'Axon Trade product update— October 11, 2018 | Axon Trade',
        description: 'Axon Trade product update — October 11, 2018',
        keywords: 'equity,fx,product update,product updates',
        og_type: 'article',
        imgUrl: '/assets/blog-card-images/axon-trade-product-update-october-2018-1.png',
      },
    },
  },
  {
    path: 'top-100-blockchain-and-crypto-influencers-on-twitter-to-follow',
    loadComponent: () =>
      import(
        './pages/blog/pages/top-100-blockchain-and-crypto-influencers-on-twitter-to-follow/top-100-blockchain-and-crypto-influencers-on-twitter-to-follow.component'
      ).then((m) => m.Top100BlockchainAndCryptoInfluencersOnTwitterToFollowComponent),
    data: {
      sitemap: {
        loc: 'top-100-blockchain-and-crypto-influencers-on-twitter-to-follow',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'top-100-blockchain-and-crypto-influencers-on-twitter-to-follow',
        fileName: '/top-100-blockchain-and-crypto-influencers-on-twitter-to-follow',
        title: 'Top 100 Blockchain and Crypto Influencers on Twitter | Axon Trade',
        description:
          'The Axon Trade team has compiled a list of Twitter accounts you probably would be interested in following if you work in blockchain & crypto related fields',
        keywords: 'twitter,influencers,crypto,industry',
        og_type: 'article',
        imgUrl:
          '/assets/blog-card-images/axon-trade-top-100-blockchain-and-crypto-influencers-on-twitter-to-follow.png',
      },
    },
  },
  {
    path: 'coming-soon',
    loadComponent: () => import('./pages/coming-soon/coming-soon.component').then((m) => m.ComingSoonComponent),
    data: {
      sitemap: {
        loc: 'coming-soon',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'coming-soon',
        fileName: '/coming-soon',
        title: 'Coming soon | Axon Trade',
        description:
          'This page is currently under construction and will be live shortly. We’re committed to providing you with meaningful content and updates, and we’re grateful for your ongoing support.',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
      },
    },
  },
  {
    path: 'booking-confirmation',
    loadComponent: () =>
      import('./pages/booking-confirmation/booking-confirmation.component').then((m) => m.BookingConfirmationComponent),
    data: {
      sitemap: {
        loc: 'booking-confirmation',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: 'booking-confirmation',
        fileName: '/booking-confirmation',
        title: 'Booking confirmation | Axon Trade',
        description: 'You have successfully booked a call on Calendly',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
      },
    },
  },
  {
    path: '404.html',
    loadComponent: () => import('./pages/page404/page404.component').then((m) => m.Page404Component),
    data: {
      sitemap: {
        loc: '404.html',
        lastmod: '2024-11-06',
      },
      meta: {
        loc: '404.html',
        fileName: '/404.html',
        title: '404 | Axon Trade',
        description:
          'We’re sorry, but the page you’re looking for could not be found. It may have been moved, deleted, or never existed in the first place.',
        imgUrl: '/assets/twitter-card/axon-trade-home-page-twitter-card.jpg',
      },
    },
  },
  {
    path: '**',
    component: Page404Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(filePath)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
